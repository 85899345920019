<template>
    <layout-default>
            <!--
            <iframe src="https://www.notioniframe.com/notion/ihzdx19p4d"
                style="
                width: 100%;
                height: 150vh;
                border: 0;
                padding: 0
                ">
            </iframe>
            -->
            <iframe
                src="https://e.notionhero.io/e1/p/bfd1d42-30fbf7282c9d43204968b1951792d83"
                style="
                width: 100%;
                height: 150vh;
                border: 0;
                padding: 0
                "></iframe>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';

export default {
    name: 'Blog',
    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    },
    components: {
        LayoutDefault
    }
};
</script>
